import http from './http'

class AdminApi {
  constructor() {
    this.client = http.axios
  }
  getUsers = () => this.client.get('/admin/users')
  getAccounts = () => this.client.get('/admin/accounts')
  getAccountById = (accountId) => this.client.get('/admin/accounts/' + accountId)
}

const admin = new AdminApi()
export default admin
