import { Button, Group, Card, Text, Stack } from '@mantine/core'
import { IconKey } from '@tabler/icons-react'

import sales from 'api/sales'

import Preload, { usePreload } from 'components/PreLoad/PreLoad'

const ApiKey = ({ edge }) => {
  const resolve = usePreload()
  const { data, onUpdate } = resolve

  const { origin } = window.location

  const settings = data || edge
  return (
    <Preload {...resolve}>
      <Group align="center" justify="space-around">
        <Button variant="default" size="sm" radius="sm" onClick={() => onUpdate(sales.createApiKey)} leftSection={<IconKey stroke={1} size={16} />}>
          Generate new Api Key
        </Button>
      </Group>

      {settings && (
        <Stack gap="1em">
          <Stack gap="0">
            <Text size="sm" margin="sm" fw={500}>
              API Key:
            </Text>
            <Card padding="lg" radius="md" withBorder>
              <Group align="center" justify="center">
                <Text size="xs" fw={500} c="green.8">
                  {settings.apiKey}
                </Text>
              </Group>
            </Card>
          </Stack>

          <Stack gap="0">
            <Text size="sm" margin="sm" fw={500}>
              Intergration iFrame:
            </Text>
            <Card padding="lg" radius="md" withBorder>
              <Group align="center" justify="center">
                <Text size="xs" fw={500} c="green.8">
                  <code>
                    &lt;iframe src=&quot;{origin}/form/{settings.apiKey}&quot; width=&quot;100%&quot; height=&quot;500px&quot; style=&quot;border: none&quot; /&gt;
                  </code>
                </Text>
              </Group>
            </Card>
          </Stack>
        </Stack>
      )}
    </Preload>
  )
}

export default ApiKey
