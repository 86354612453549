import { Navigate } from 'react-router-dom'

import Billing from './Billing/Billing'
import BriefPage from './Brief/Brief'
import Users from './Admin/Users/Users'

import AdminAllBriefs from './Admin/AllBriefs/AllBriefs'
import EditUser from './Admin/EditUser/EditUser'
import EditConfig from './Admin/EditConfig/EditConfig'
import Dashboard from './Dashboard/Dashboard'

import UserProfile from './User/Profile/Profile'
import UserAccount from './User/Account/Account'

import Reports from './Reports/Reports'
import Report from './Report/Report'
import AllBriefs from './Admin/AllBriefs/AllBriefs'
import AdminBrief from './Admin/Full/Full'
import AdminBriefsList from './Admin/BriefsList/BriefsList'
import BattleCardList from './BattleCard/List/List'
import BattleCard from './BattleCard/BattleCard'

import Orders from './Orders/Orders'
import OrderPage from './Orders/Page/OrderPage'
import OrderPreview from './Orders/PdfPreview/PdfPreview'

import Leads from './Leads/Leads'
import Customers from './Customers/Customers2'
import CustomerEmail from './Customers/CustomerEmail/CustomerEmail'

import Products from './Products/Products'
import Workflows from './Workflows/Workflows'
import Integrations from './Integrations/Integrations'

import Enquiry from './Enquiry/Enquiry'
import Requests from './Requests/ListPage'
import RequestPage from './Requests/Page/RequestPage'
import Settings from './Settings/Settings'

import adminRoutes from './Admin/AdminRoutes'

const screens = [
  {
    path: '/',
    element: <Dashboard />,
    permissions: 'user',
  },
  {
    path: '/dashboard',
    element: <Dashboard />,
    permissions: 'user',
  },
  {
    path: '/enquiry',
    element: <Enquiry />,
    permissions: 'user',
  },
  {
    path: '/o2c/orders/:orderId',
    element: <OrderPage />,
    permissions: 'user',
  },
  {
    path: '/o2c/orders/:orderId/invoice',
    element: <OrderPreview />,
    permissions: 'user',
  },

  {
    path: '/o2c/requests-for-quote',
    element: <Requests />,
    permissions: 'user',
  },
  {
    path: '/o2c/requests-for-quote/:id',
    element: <RequestPage />,
    permissions: 'user',
  },

  {
    path: '/o2c/orders',
    element: <Orders />,
    permissions: 'user',
  },
  {
    path: '/leads',
    element: <Leads />,
    permissions: 'user',
  },

  {
    path: '/products',
    element: <Products />,
    permissions: 'user',
  },
  {
    path: '/customers',
    element: <Customers />,
    permissions: 'user',
  },
  {
    path: '/customers/enquiries/:id',
    element: <CustomerEmail />,
    permissions: 'user',
  },
  {
    path: '/products',
    element: <Products />,
    permissions: 'user',
  },
  {
    path: '/workflows',
    element: <Workflows />,
    permissions: 'user',
  },
  {
    path: '/integrations',
    element: <Integrations />,
    permissions: 'user',
  },

  {
    path: '/AllBriefs',
    element: <AllBriefs />,
    permissions: 'user',
  },
  {
    path: '/test',
    element: <EditConfig />,
    permissions: 'user',
  },
  {
    path: '/billing',
    element: <Billing />,
    permissions: 'user',
  },
  {
    path: '/dashboard',
    element: <Dashboard />,
    permissions: 'user',
  },
  {
    path: '/battle-cards',
    element: <BattleCardList />,
    permissions: 'user',
  },
  {
    path: '/battle-cards/:id',
    element: <BattleCard />,
    permissions: 'user',
  },
  {
    path: '/reports',
    element: <Reports />,
    permissions: 'user',
  },
  {
    path: '/reports/:reportId',
    element: <Report />,
    permissions: 'user',
  },
  {
    path: '/user/profile',
    element: <UserProfile />,
    permissions: 'user',
  },
  {
    path: '/user/account',
    element: <UserAccount />,
    permissions: 'user',
  },
  {
    path: '/admin/briefs/:broadnID',
    element: <BriefPage />,
    permissions: 'admin',
  },
  {
    path: '/admin/briefs',
    element: <AdminBriefsList />,
    permissions: 'admin',
  },
  {
    path: '/admin/briefs-v2/:broadnID',
    element: <AdminBrief />,
    permissions: 'admin',
  },
  {
    path: '/admin/users',
    element: <Users />,
    permissions: ['broadn'],
  },
  {
    path: '/admin/users/:userid',
    element: <EditUser />,
    permissions: 'admin',
  },
  {
    path: '/admin/allbriefs',
    element: <AdminAllBriefs />,
    permissions: 'admin',
  },
  {
    path: '/admin/config',
    element: <EditConfig />,
    permissions: 'admin',
  },
  {
    path: '/settings',
    element: <Settings />,
    permissions: 'user',
  },
  {
    path: '/',
    element: <Navigate to="/login" />,
  },
  ...adminRoutes,
]

export default screens
