import auth from 'api/auth'
import { observer } from 'mobx-react'
import { VscDashboard } from 'react-icons/vsc'
import {
  IconHome2,
  IconUsersGroup,
  IconSeeding,
  IconBriefcase,
  IconCashRegister,
  IconTags,
  IconPlugConnected,
  IconBrightness2,
  IconRoute,
  IconMailSpark,
  IconReorder,
} from '@tabler/icons-react'

import UserCard from '../UserCard/UserCard'
import NavList from './NavList'

import css from './Nav.module.css'

const anonymousList = [{ label: 'Home', Icon: IconHome2, to: '/' }]

const enquiryList = [{ label: 'Enquiry', Icon: IconMailSpark, to: '/enquiry' }]

const userList = [
  { label: 'Dashboard', Icon: VscDashboard, to: '/' },
  {
    label: 'Orders to cash',
    Icon: IconReorder,
    children: [
      { label: 'Request For Quote', Icon: IconCashRegister, to: '/o2c/requests-for-quote' },
      { label: 'Orders', Icon: IconCashRegister, to: '/o2c/orders' },
    ],
  },
  { label: 'Products', Icon: IconTags, to: '/products' },
  { label: 'Leads', Icon: IconSeeding, to: '/leads' },
  { label: 'Customers', Icon: IconBriefcase, to: '/customers' },
]

const settingsList = [
  { label: 'Workflows', Icon: IconRoute, to: '/workflows' },
  { label: 'Integrations', Icon: IconPlugConnected, to: '/integrations' },
  { label: 'Settings', Icon: IconBrightness2, to: '/settings' },
]

const adminList = [
  { label: 'All users', Icon: IconUsersGroup, to: '/admin/allusers' },
  { label: 'All accounts', Icon: IconUsersGroup, to: '/admin/accounts' },
]

const LayoutNav = () => {
  const user = auth.user
  const { roles } = user || {}
  const isAdmin = roles && roles.includes('admin')

  return (
    <div className="layout__nav">
      <div className="--sticky">
        <UserCard />

        {user ? (
          <>
            <NavList list={enquiryList} />
            <NavList list={userList} />
            <NavList list={settingsList} />
          </>
        ) : (
          <NavList list={anonymousList} />
        )}

        {isAdmin && <NavList list={adminList} />}
      </div>
    </div>
  )
}

export default observer(LayoutNav)
